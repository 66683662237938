<template>
  <div class="research_box">
        <div class="job_contant">
            <div class="job_detail">
                <div class="job_title">
                    <p class="job_name">java高级研发工程师</p>
                    <p class="job_type">上海秉匠科技项目部     |     全职     |     开发类</p>
                    <p class="job_adress">上海市·徐汇区</p>
                    <p class="job_time">发布时间：2020.9.25</p>
                </div>
                <div class="job_view_detail">
                    <router-link to="/positionDetails_javakf">
                        查看详情   >>
                    </router-link>
                </div>
            </div>
            <div class="job_detail">
                <div class="job_title">
                    <p class="job_name">高级开发工程师（前端）</p>
                    <p class="job_type">上海秉匠科技项目部     |     全职     |     开发类</p>
                    <p class="job_adress">上海市·徐汇区</p>
                    <p class="job_time">发布时间：2020.9.25</p>
                </div>
                <div class="job_view_detail">
                    <router-link to="/positionDetails">
                        查看详情   >>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style>
</style>
